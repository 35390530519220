import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import arrow from '../images/up-right-arrow.svg';

export default function Events({ events }) {
  return (
    <section>
      <div className="container my-8">
        <h2 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-blue font-bold">
          Selected past events
        </h2>

        <div
          className="
            grid grid-cols-11 xl:grid-cols-12
            gap-x-8 gap-y-12 md:gap-y-16 lg:gap-y-20 xl:gap-y-24
            mt-6 sm:mt-10 md:mt-16 lg:mt-24
          "
        >
          {events.map((event, index) => (
            <Event key={index} event={event} index={index} />
          ))}
        </div>
      </div>
    </section>
  );
}

function Event({ event, index }) {
  let isEven = index % 2 == 0;

  let {
    allFile: { nodes },
  } = useStaticQuery(graphql`
    query EventImages {
      allFile(filter: { relativeDirectory: { eq: "events" } }) {
        nodes {
          base
          childImageSharp {
            gatsbyImageData(width: 800, quality: 90)
          }
        }
      }
    }
  `);

  let image = nodes.find(({ base }) => base == event.image);

  return (
    <div
      className={`
        ${isEven ? 'xl:col-start-2' : ''}
        col-span-11
        flex flex-col
        md:grid md:grid-cols-11 md:gap-8
        grid-flow-col-dense
      `}
    >
      <div className="md:col-span-6 lg:col-span-7">
        <GatsbyImage
          image={image.childImageSharp.gatsbyImageData}
          alt={event.title}
          className="rounded-md"
        />
      </div>

      <div
        className={`
          ${isEven ? 'md:col-start-1 lg:col-start-1' : ''}
          md:col-span-5 lg:col-span-4
          mt-6 md:mt-0
        `}
      >
        <h3 className="text-xl sm:text-2xl lg:text-3xl font-bold text-black">{event.title}</h3>
        <p
          className="
            text-base sm:text-lg text-black text-opacity-70
            leading-relaxed mt-4 md:mt-8
          "
        >
          <strong>Client:</strong> {event.client}
        </p>
        <p className="text-base sm:text-lg  text-black text-opacity-70 leading-relaxed mt-2">
          <strong>Event name: </strong> {event.name}
        </p>
        <p className="text-base sm:text-lg  text-black text-opacity-70 leading-relaxed">
          {event.description}
        </p>

        <div className="w-max mt-6">
          <a
            href={event.link}
            className="
              flex items-center space-x-2 rounded-sm transition focus-ring hover:bg-active-grey
              bg-light-grey py-3 px-7 text-primary text-base font-bold
            "
          >
            <span>Visit Event</span>
            <img src={arrow} alt="Arrow" />
          </a>
        </div>
      </div>
    </div>
  );
}
