import React from 'react';
import check from '../images/check.svg';

export default function EventTypeInfo({ title, text, checks, image }) {
  return (
    <section className="bg-dark-white">
      <div
        className="
          container xl:pt-24 lg:pt-20 md:pt-16 sm:pt-12 pt-8
          xl:pb-36 lg:pb-28 md:pb-24 sm:pb-16 pb-12
        "
      >
        <div className="grid grid-flow-row-dense md:grid-cols-2 gap-8">
          <div className="md:col-span-2 md:block hidden xl:hidden">
            <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-blue mb-4 md:mb-5">
              {title}
            </h2>
          </div>
          <div className="col-span-1 self-center">
            <h2
              className="
                md:hidden xl:block text-3xl sm:text-4xl lg:text-5xl font-bold
                text-blue md:mb-5 mb-3
              "
            >
              {title}
            </h2>
            <p className="text-blue text-base sm:text-lg md:text-xl leading-7 md:mb-8 mb-6">
              {text}
            </p>
            <div>
              {checks.map((item, index) => (
                <p
                  className="flex items-center space-x-3 text-blue text-lg md:text-xl mb-2"
                  key={index}
                >
                  <img src={check} alt="Check" />
                  <span>{item}</span>
                </p>
              ))}
            </div>
          </div>

          <div className="row-start-1 md:row-start-auto col-span-1 self-center">{image}</div>
        </div>
      </div>
    </section>
  );
}
