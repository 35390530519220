import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import AllIncluded from '../components/AllIncluded';
import Header from '../components/Header';
import Hero from '../components/Hero';
import Partners from '../components/Partners';
import Premium from '../components/Premium';
import ReadyToStart from '../components/ReadyToStart';
import Footer from '../components/Footer';
import EventTypeInfo from '../components/EventTypeInfo';
import Events from '../components/Events';
import FAQ from '../components/FAQ';
import SEO from '../components/SEO';
import metaImage from '../images/meta/virtual-event-streaming.jpg';

const VirtualEvents = () => {
  return (
    <div>
      <SEO
        title="Virtual event streaming"
        description="Grow your audience, everywhere. Stream your virtual event engaging your community around the world, wherever they watch."
        image={metaImage}
      />

      <Header />
      <Hero
        title="Virtual event services you can rely on"
        subtitle="Our team will guide you every step of the way and deliver the best online experience for your audience"
      />
      <Partners />
      <EventTypeInfo
        image={
          <StaticImage
            src="../images/virtual-events/virtual-event-streaming.png"
            alt="Collage of a virtual event being streamed"
          />
        }
        title="Grow your audience, everywhere"
        text="Engage your community around the world, wherever they watch. Stream to any device across social media platforms or your own website. Throw stellar events and make your organization thrive, with webcasting."
        checks={[
          'Virtual conference',
          'Science & Education webinars',
          'Political discussion',
          'Community gathering',
        ]}
      />
      <AllIncluded />
      <Premium />
      <Events
        events={[
          {
            image: 'renew-europe-stream.jpg',
            title: 'High profile virtual event with interactive program',
            client: 'Renew Europe',
            link: 'https://app.livecasts.eu/a-vision-for-rural-europe/program',
            name: 'A Vision for Rural Europe',
            description:
              'Streamed in multiple languages, feeding in a live studio as well as pre recorded video messages.',
          },
          {
            image: 'eu-cyber-forum-stream.jpg',
            title: 'Global multi-day event with mini site',
            client: 'EUISS',
            link: 'https://eucyberforum.eu',
            name: 'EU Cyber Forum 2020',
            description:
              'A custom mini site hosted the live event with additional information on the program and the speakers.',
          },
        ]}
      />
      <ReadyToStart />
      <FAQ type="virtual" />
      <Footer />
    </div>
  );
};

export default VirtualEvents;
